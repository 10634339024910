.card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;

    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}