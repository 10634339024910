.link-container {
    box-sizing: border-box;
    width: max-content;
    padding: 8px 20px;
    background-color: var(--indigo);
    border-radius: 500px;
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    border: 1px solid var(--indigo);
}

.link-container:hover {
    background-color: var(--dark-indigo);
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}

.link-container-outlined {
    box-sizing: border-box;
    width: max-content;
    padding: 8px 20px;
    border-radius: 500px;
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    border: 1px solid var(--indigo);
}

.link-container-outlined:hover {
    background-color: var(--faint);
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}
