.button-container {
    padding: 8px 20px;
    background-color: var(--indigo);
    width: max-content;
    border-radius: 500px;
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
}

.button-container:hover {
    background-color: var(--dark-indigo);
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}
