.testimonial-container-width {
    width: 300px;

    @media (min-width: theme(screens.md)) {
        width: 500px;
    }
}

.testimonial-container-border {
    border: 1px solid var(--silver);
}

.testimonial-name-border {
    border-top: 1px solid var(--silver);
}

.testimonial-apostrophe {

    color: var(--silver, #A1B2C4);
    font-family: Gloock, serif;
    font-size: 8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8rem;
    margin-right: -1.75rem;


    @media (min-width: theme(screens.md)) {
        font-size: 16rem;
        letter-spacing: 1.6rem;
        margin-right: -3.5rem;
    }
}

.truncated-text {
    display: -webkit-box;       /* Required for line-clamp */
    -webkit-line-clamp: 6;     /* Number of lines to limit */
    -webkit-box-orient: vertical; /* Required for line-clamp */
    overflow: hidden;          /* Hides the overflowing text */
    text-overflow: ellipsis;   /* Adds ellipsis for truncated text */
  }

  