.unordered-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}