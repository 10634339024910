
.header-container {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (min-width: theme(screens.md)) {
        flex-direction: row;
        padding: 3rem 4rem;
        justify-content: space-between;
        align-items: center;
    }
}

.nav-container {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}