
.bottom-sheet-icon {
    height: 1.5rem;
    width: 1.5rem;

    @media (min-width: theme(screens.md)) {
        height: 3rem;
        width: 3rem;
    }
}
  
.bottom-sheet-container {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (min-width: theme(screens.md)) {
        padding: 4rem;
        gap: 4rem;
    }
}

.bottom-sheet-upper-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 6rem;
}

.bottom-sheet-controls {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
}

.bottom-sheet-heading-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}

.bottom-sheet-lower-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 3rem;
    }
}

.bottom-sheet-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;

    @media (min-width: theme(screens.md)) {
        gap: 2rem;
        width: 25rem;
    }
}