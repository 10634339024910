.navigation-link-container {
    display: inline-flex;
    padding: 0.25rem 0rem;
    align-items: flex-start;
    gap: 0.625rem;
    transition: border 0.25s ease-in-out;
}

.navigation-link-container:hover {
    border-bottom: 2px solid var(--dark);
}

.navigation-link-unselected {
    border-bottom: 2px solid var(--light);   
}

.navigation-link-selected {
    border-bottom: 2px solid var(--dark);
}