.overview-item-container {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: theme(screens.md)) {
        gap: 0.5rem;
    }
}