.overview-section-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0rem 1rem;

    @media (min-width: theme(screens.md)) {
        flex-direction: row !important;
        justify-content: center;
        align-items: flex-start !important;
        gap: 4rem;
        padding: 0rem 4rem;
    }
}