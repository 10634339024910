
.logo-container {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
}

.logo-type {
    color: var(--dark);
    font-family: Figtree, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}