.tab-active-container {
    border-radius: 500px;
    border: 2px solid var(--dark);
    padding: 0.25rem 1rem;
    background-color: var(--faint);
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}

.tab-inactive-container {
    border-radius: 500px;
    border: 1px solid var(--silver);
    padding: 0.25rem 1rem;
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}

.tab-inactive-container:hover {
    background-color: var(--faint);
}