.user-quote-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.apostrophe {
    color: var(--silver);
    font-family: sans-serif;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6rem;
    margin-right: -3.5rem;
    z-index: 1;
}

.quote-outer-container {
    display: flex;
    padding-top: 40px;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
}

.quote-container {
    display: flex;
    padding: 1.5rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.625rem;
    flex: 1 0 0;
    border: 1px solid var(--silver);
    background: var(--light);
}