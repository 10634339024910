.content-section-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 0rem 1rem;

    @media (min-width: theme(screens.md)) {
        gap: 4rem !important;
        padding: 0rem 4rem;
    }
}

.special-class {
    padding-top: 1rem;
    border-color: var(--silver);
    border-top: 0.5px solid;
    border-bottom: 0.5px solid;

    @media (min-width: theme(screens.md)) {
        padding-top: 4rem;
    }
}