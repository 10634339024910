input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid var(--light);

    @media (min-width: theme(screens.md)) {
        padding: 1rem;
    }

}

.input-field-container {
    width: 100%;
}

input::-webkit-input-placeholder {
    color: var(--silver);
}

input::-moz-placeholder {
    color: var(--silver);
}

input:-ms-input-placeholder {
    color: var(--silver);
}

input:-moz-placeholder {
    color: var(--silver);
}

input:-webkit-input-placeholder {
    color: var(--silver);
}

label {
    position: absolute;
    left: -9999px;
    aria-hidden: true;
  }
  