.competitions-grid-container {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 4rem;

    @media (min-width: theme(screens.md)) {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        align-content: flex-start;
        gap: 4rem;
        flex-wrap: wrap;
    }
    
}

.competition-container {

    width: 100%;

    @media (min-width: theme(screens.md)) {
        width: calc((100% - 8rem)/3);
    }
    
}