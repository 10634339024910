.welcome-container {
    width: 100%;
    /* height: calc(100vh - 14rem); */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 8rem;

    @media (min-width: theme(screens.md)) {
        padding-top: 4rem;
    }
}

.scroll-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 1rem 0;
  }
  
  .scroll {
    white-space: nowrap;
  }
  
  .scroll div {
    display: flex;
    gap: 2em;
  }
  
  
  .right-to-left {
    animation: right-to-left 20s infinite linear;
  }
  
  @keyframes  right-to-left {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  
  


.featured-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
    padding: 0rem 1rem;

    @media (min-width: theme(screens.md)) {
        padding: 0rem 4rem;
    }
}

.homepage-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    @media (min-width: theme(screens.md)) {
        gap: 8rem;
    }
}

.homepage-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media (min-width: theme(screens.md)) {
        gap: 8rem;
    }
}