.footer-container {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* border-top: 1px solid var(--silver); */

    @media (min-width: theme(screens.lg)) {
        padding: 4rem;
        gap: 8rem;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
    }
}

/* .connect-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
} */

.connect-icons-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: max-content;
    min-width: max-content;
}

.copyright-statement {
    text-align: center;

    @media (min-width: theme(screens.lg)) {
        text-align: left;
        width: max-content;
    }
}

