.bullet-point-overall-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    width: 100%;

    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}

.bullet-point-container {
    display: flex;
    width: 100%;
    gap: 1rem;

    @media (min-width: theme(screens.md)) {
        gap: 2rem;
    }
}

.bullet-point-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    @media (min-width: theme(screens.md)) {
        gap: 0.5rem;
    }
}

