.section-header-container {
    display: flex;
    width: 100%;
    gap: 1rem;

    @media (min-width: theme(screens.md)) {
        gap: 2rem;
    }
}

.section-header-underline {
    width: 32px;
    height: 4px;
    background-color: var(--silver);
    border-radius: 500px;

    @media (min-width: theme(screens.md)) {
        width: 32px;
        height: 4px;
        background-color: var(--silver);
    }
}