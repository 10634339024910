
.page-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 8rem;
    }
}

.sections-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 8rem !important;
    }
}

.hero-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    align-self: stretch;
}

.divider {
    width: 100%;
    border-color: var(--silver);
}