.project-card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    
    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}

.project-card-container:hover {
    transform: scale(98%);
    transition: all 200ms cubic-bezier(.02, .01, .47, 1);
    transition-delay: 0s !important;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    @media (min-width: theme(screens.md)) {
        gap: 0.5rem;
    }
}

.tags-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}



