@tailwind base;
@tailwind components;
@tailwind utilities;



/* color styles*/

:root {
  --dark: #27333F;
  --grey: #4F657D;
  --silver: #A1B2C4;
  --pale: #eeeff0;
  --faint: #F3F4F5;
  --light: #FAFAFA;
  --indigo: #4E6CD9;
  --dark-indigo: #4661c3;

  --red: #F83A57;
  --blue: #3A96F8;
}

.indigo {
  color: var(--indigo);
}

.light {
  color: var(--light);
}

.dark {
  color: var(--dark);
}

.grey {
  color: var(--grey);
}

.background-dark {
  background-color: var(--dark);
}

/* text styles */

.h1 {
  /* H1/sm */
  font-family: Figtree, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  

  @media (min-width: theme(screens.md)) {
    /* H1/lg */
    font-family: Figtree, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    
  }

}

.h2 {
  /* H2/sm */
  font-family: Figtree, sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  

  @media (min-width: theme(screens.md)) {
    /* H2/lg */
    font-family: Figtree, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    

  }
}

.h3 {
  /* H3/sm */
  font-family: Figtree, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  

  @media (min-width: theme(screens.md)) {
    /* H3/lg */
    font-family: Figtree, sans-serif;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    
  }
}

.h3-sans {
  /* H3-sans/sm */
  font-family: Figtree, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  

  @media (min-width: theme(screens.md)) {
    /* H3-sans/lg */
    font-family: Figtree, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    
  }
}

.h4 {
  /* H4/sm */
  font-family: Figtree, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (min-width: theme(screens.md)) {
    /* H4/lg */
    font-family: Figtree, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

}

.h5 {
    /* H5/sm */
    font-family: Figtree, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    @media (min-width: theme(screens.md)) {
      /* H5/lg */
      font-family: Figtree, sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
}

.h6 {
  /* H6/sm */
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (min-width: theme(screens.md)) {
    /* H6/lg */
    font-family: Figtree, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  
}

.body {
  /* Body/sm */
  font-family: Figtree, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: theme(screens.md)) {
    /* Body/lg */
    font-family: Figtree, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.caption {
  /* Caption/sm */
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: theme(screens.md)) {
    /* Caption/lg */
    font-family: Figtree, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

html, body{
  cursor: url('/public/cursor.svg') 16 16, auto;
}

a:hover{
  cursor: pointer;
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeInSlideUp 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

body {
  background-color: var(--light);
  color: var(--grey);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

  @media (min-width: theme(screens.md)) {
    margin: 0;

  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
