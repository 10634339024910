.tew-text-content-container {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    
    @media (min-width: theme(screens.md)) {
        gap: 1rem;
    }
}

.tew-icon-with-text-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex: 1 0 0;
}

.tew-icon-size {
    width: 2rem;
    height: 2rem;

    @media (min-width: theme(screens.md)) {
        width: 3rem;
        height: 3rem;
    }
}