/* not used */
.projects-grid-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;

    @media (min-width: theme(screens.md)) {
        display: flex;
        flex-direction: row !important;
        align-items: flex-start;
        gap: 4rem;
    }
}

.projects-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
    align-self: stretch;
    width: 50%;
}

.extra-padding-top {
    padding-top: 256px;
}