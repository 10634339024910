.case-study-banner-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 1rem;

    @media (min-width: theme(screens.md)) {
        gap: 2rem;
        padding: 0rem 4rem;
    }
}